<template>
  <div>
    <div
      class="market-solution-section-list-wrap"
      v-if="listData.list && listData.list.length"
    >
      <div
        class="rno-lattice rno-lattice-cols-4 rno-lattice-cols-m-1 rno-lattice-cols-s-1 rno-lattice-without-margin"
      >
        <div
          class="rno-lattice-cell"
          v-for="(solution, index) in listData.list"
          :key="index"
        >
          <div class="market-solution-panel">
            <div class="market-solution-panel-hd">
              <div class="market-solution-panel-view">
                <img :src="solution.img" alt="" />
              </div>
            </div>
            <div class="market-solution-panel-bd">
              <div class="market-solution-panel-title">{{ solution.name }}</div>
              <div class="market-solution-panel-cont">
                <div
                  class="market-solution-panel-desc"
                  :title="solution.description"
                >
                  {{ solution.description }}
                </div>
                <div
                  class="market-solution-panel-company"
                  :title="solution.companyName"
                >
                  {{ solution.companyName }}
                </div>
                <div class="market-solution-panel-price">
                  <div class="market-solution-panel-prices">
                    <span class="market-solution-panel-prices-num"
                      ><i>¥</i>{{ solution.hardwarePrice / 100 || 0
                      }}<em>起</em></span
                    >
                  </div>
                  <a
                    class="rno-btn rno-btn-hole rno-btn-s"
                    href=""
                    target="_blank"
                    @click.prevent="jump_solution(solution)"
                    ><span class="rno-btn-text">立即购买</span></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jump } from '../../util/jumpPage'

export default {
  name: 'solutionchosenlist',
  props: {
    listData: {
      type: Object,
      default: {
        total: 0,
        list: [],
      },
    },
  },
  methods: {
    jump_solution(solution) {
      window.open(`${solution.cloudMarketUrl}`)
    },
  },
}
</script>
